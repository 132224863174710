import {ApplicationError, ErrorLevel, ErrorType, Language, LogLevels} from 'src/types'
import { auth, db } from '../firebase'

export const userSession: producer = async ({
  isReady = observe.session.firebase.isReady,

  userObj = update.user,
  setRawUser = update.rawUser,
  setIsAuth = update.user.isAuth,
  setUid = update.user.uid,

  error = update.errors[param.id]
}) => {
  if (!isReady) return
  try {
    await auth.onAuthStateChanged(async (user: any) => {
      console.log('user >>>>>', user)
      if (!user) {
        userObj.remove()
        setIsAuth.set(false)
        return
      }
      const uid = user.uid
      const doc = await db.collection('users').doc(uid).get()
      let userInfo: any
      if (!doc.exists) {
        userInfo = {
          uid,
          data: {
            displayName: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            language: Language.NORWEGIAN,
            photoURL: user.photoURL,
            isAnonymous: user.isAnonymous,
            role: '',
            providerData: JSON.parse(JSON.stringify(user.providerData))
          }
          // raw: user
        }
      } else {
        userInfo = doc.data()
      }

      const result = await user.getIdTokenResult()
      console.log('result', result)
      // KLP Auth
      if (
        result.signInProvider == 'oidc.klp-dev' ||
        result.signInProvider == 'oidc.klp-staging' ||
        result.signInProvider == 'oidc.klp-prod'
      ) {
        const attributes = result.claims.firebase.sign_in_attributes
        if (!Array.isArray(attributes.role)) attributes.role = [attributes.role]
        let roles = attributes.role
          .map((role: any) => {
            switch (role) {
              case 'admin':
                return 'manager'
              case 'superuser':
                return 'tenant'
              default:
                return ''
            }
          })
          .filter((role: string) => !!role)
        console.log('roles', roles, user)
        const userSnap = await db.collection('KLPUsers').where('email', '==', user.displayName).get()
        const userData = userSnap?.docs?.[0].data() || {}

        userInfo.data.role = roles.indexOf('manager') >= 0 ? 'manager' : 'tenant'
        userInfo.data.email = user.displayName
        userInfo.data.firstName = userData.firstName ?? ''
        userInfo.data.lastName = userData.lastName ?? ''
        userInfo.data.hasCustomerPortalInterest = userData.hasCustomerPortalInterest ?? false
        userInfo.data.superOfficeId = userData.id ?? ''
        userInfo.data.displayName = `${userInfo.data.firstName} ${userInfo.data.lastName}`

        const kunder = attributes?.kunder
        let companies: any = []
        if (kunder) {
          if (kunder.includes(';')) {
            companies = kunder.split(';')
          } else {
            companies = [kunder]
          }
        }
        console.log('companies', companies)
        const tenantsSnap = await db.collection('tenants').where('number', 'in', companies).get()
        console.log('tenantsSnap', tenantsSnap.docs)
        userInfo.data.tenantId = tenantsSnap.docs.map((doc) => (doc.data()).id)
      }

      // GOOGLE Auth
      if (result.signInProvider == 'google.com') {
        if (!userInfo?.data?.role) userInfo.data.role = 'tenant'
        if (userInfo?.data?.roles?.length < 1) userInfo.data.roles = [userInfo.data.role]
      }
      console.log('saving user info', userInfo)
      await db.collection('users').doc(uid).set(userInfo, { merge: true })

      setIsAuth.set(true)
      setUid.set(user.uid)
      setRawUser.set(user)
    })
  } catch (e) {
    console.error('error on setting user =>', e)
    error.set(
      {
        message: e.message || String(e || 'error-on-login'),
        level: LogLevels.FATAL,
      } as ApplicationError,
      { id: 'userSession' }
    )
  }
}
